import { MetaDataNextSEO } from 'components/MetaDataNextSEO/MetaDataNextSEO';
import ClientNotFoundPage from 'components/PageLevelComponents/ClientNotFoundPage';
import { FC } from 'react';
import { ErrorPageController } from 'server/controller/page/error/errorPageController';
import { LOCALE_DEFAULT } from 'config/config';

type ErrorPageProps = {
  locale: string;
  errorData: any;
  metaData: any;
};

const GlobalErrorPage: FC<ErrorPageProps> = ({ locale, errorData, metaData }) => {
  return (
    <>
      {metaData && <MetaDataNextSEO metaData={metaData} locale={locale} />}
      <ClientNotFoundPage locale={locale} errorData={errorData} />
    </>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  return ErrorPageController.getErrorPageData({ locale: locale ?? LOCALE_DEFAULT });
}

export default GlobalErrorPage;
